import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  DateField,
  SelectField,
  TextField,
  useRecordContext
} from "react-admin";
import { useGetIdentity } from "react-admin";

import { useFlags } from "../../../../clients/launchDarkly";

import { Project } from "../../../../types";
import iconAddPatient from "../../../../shared/icon/add-patient.svg";
import PrimaryActionButton from "../../../common/buttons/PrimaryActionButton";
import CreateCohortDialog from "../../cohort/CohortCreate";
import CreateReferralCodeDialog from "../../referralcode/ReferralCodeCreate";
import RemoveReferralCode from "../../referralcode/ReferralCodeRemove";

import {
  ContentHeaderContainer,
  ContentDetail,
  ContentDetailTopRow,
  ContentDetailTopLine,
  ContentDetailMiddleRow,
  ContentDetailLastRow,
  ContentDetailDescription,
  ContentAction
} from "../../../common/ContentHeader";

import { Badge } from "../../../fields/Badge";
import { runeTheme } from "../../../common/RuneTheme";
import { DataExportDialog } from "./DataExportDialog";

const AddPatientToProjectButton = () => {
  const project = useRecordContext<Project>();

  return (
    <PrimaryActionButton
      link="/ProjectPatient/create"
      state={{
        record: {
          project_id: project.id,
          project_title: project.title,
          status: "UNKNOWN_PATIENT"
        }
      }}
      icon={iconAddPatient}
      label="Add Patient"
    />
  );
};

const ProjectHeader = () => {
  const { cohortTabsVisible, patientStartEndDate } = useFlags();
  const { data: user } = useGetIdentity();

  const isSuperAdmin = user?.defaultMembership?.role.superAdmin;

  const showReferralCode = isSuperAdmin;

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric"
  };

  const project = useRecordContext<Project>();
  const projectExportEnabled = project.dataExportEnabled;

  const location = useLocation();
  const navigate = useNavigate();

  const showDataExport =
    new URLSearchParams(location.search).get("showDataExport") === "true";

  const handleCloseDataExport = () => {
    const params = new URLSearchParams(location.search);
    params.delete("showDataExport");
    navigate({ search: params.toString() });
  };

  return (
    <ContentHeaderContainer>
      <ContentDetail>
        <ContentDetailTopRow>
          <ContentDetailTopLine>
            <TextField source="org.displayName" sx={{ fontSize: "1.2rem" }} />
          </ContentDetailTopLine>

          <Badge bgColor={runeTheme.palette.badge.highlight} display="flex">
            <SelectField
              source="type"
              choices={[
                { id: "EXPLORATORY", name: "EXPLORATORY" },
                { id: "CLINICAL_TRIAL", name: "CLINICAL TRIAL" },
                { id: "RETROSPECTIVE_STUDY", name: "RETROSPECTIVE STUDY" },
                { id: "PROSPECTIVE_STUDY", name: "PROSPECTIVE STUDY" },
                { id: "SANDBOX", name: "SANDBOX" }
              ]}
            />
          </Badge>
        </ContentDetailTopRow>

        <ContentDetailMiddleRow>
          <TextField source="title" className="large-textfield" />
        </ContentDetailMiddleRow>

        {showReferralCode && project.referralCode && (
          <RemoveReferralCode referralCode={project.referralCode} />
        )}

        <ContentDetailLastRow>
          Started on{" "}
          <DateField
            source="startedAt"
            sx={{ fontWeight: "900" }}
            options={dateOptions}
          />
        </ContentDetailLastRow>

        <ContentDetailDescription>
          <TextField source="description" label="Description" />
        </ContentDetailDescription>
      </ContentDetail>
      <ContentAction>
        {projectExportEnabled && (
          <DataExportDialog
            isOpen={showDataExport}
            onClose={handleCloseDataExport}
          />
        )}
        {showReferralCode && !project.referralCode && <CreateReferralCodeDialog />}
        {cohortTabsVisible && <CreateCohortDialog />}

        <AddPatientToProjectButton />
      </ContentAction>
    </ContentHeaderContainer>
  );
};

export default ProjectHeader;
