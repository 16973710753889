export enum TabIndex {
  ORG_TAB_PATIENTS = "0",
  ORG_TAB_MEMBERS = "1",

  PATIENT_TAB_DEVICES = "1"
}

export enum TimeInterval {
  PROJECT_ALL = "PROJECT_ALL",
  FOURTEEN_DAYS = "FOURTEEN_DAYS",
  NINETY_DAYS = "NINETY_DAYS"
}

export enum QueryTypes {
  CREATE = "CREATE",
  GET_LIST = "GET_LIST",
  GET_ONE = "GET_ONE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  GET_MANY = "GET_MANY",
  GET_MANY_REFERENCE = "GET_MANY_REFERENCE",
  DELETE_MANY = "DELETE_MANY"
}

export enum DeviceTypeIDs {
  APPLE_WATCH = "Apple Watch",
  ARCHIVE_DATA = "Archive Data",
  CLINICAL_ASSESMENT = "Clinical Assessment",
  MEDTRONIC_PERCEPT = "Medtronic Percept",
  MEDTRONIC_RCS = "Medtronic RC+S",
  // Device Type IDs StriveStudy and iPhone are the
  // same device. StriveStudy should be removed from this
  // list once the device migration to postgres is complete.
  STRIVE_STUDY = "StriveStudy",
  IPHONE = "iPhone"
}

export enum PerceptDeviceTypeIDs {
  MEDTRONIC_PERCEPT = "mdtpercept",
  MEDTRONIC_RCS = "RC+S"
}

export enum Status {
  ACTIVE = "ACTIVE",
  INVITED = "INVITED",
  DISABLED = "DISABLED"
}

export enum Role {
  MEMBER = "Member",
  ADMIN = "Admin",
  CLINICIAN = "Clinician"
}

export enum OrgTag {
  AGGREGATION = "AGGREGATION",
  CLINICAL = "CLINICAL",
  DEMO = "DEMO",
  INTERNAL = "INTERNAL",
  RESEARCH = "RESEARCH",
  RESEARCH_RESTRICTED = "RESEARCH_RESTRICTED"
}

export const SEC_PER_HOUR = 3600;
export const SEC_PER_DAY = 24 * SEC_PER_HOUR;
export const SEC_PER_WEEK = 7 * SEC_PER_DAY;

export const MILLI_SEC_PER_DAY = SEC_PER_DAY * 1000;
export const MILLI_SEC_PER_HOUR = SEC_PER_HOUR * 1000;

export const MIN_PROJECT_START_DATE = new Date("2015-01-01T00:00:00.000+00:00");

export const DUO_PREFIX = "Duo_";
export const RUNE_SUFFIX = "@runelabs.io";

export const USE_SSO = "useSSO";

export const STREAM_V2_PREFIX = "/v2/streams/";

export const DATA_AVAILABILITY_THRESHOLD_DAYS = 10;
export const DATA_AVAILABILITY_AT_RISK_THRESHOLD_DAYS = 5;
