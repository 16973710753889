import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";

import { QueryTypes } from "../../constants";
import { isDataOutdated } from "./utilities";
import { DATA_AVAILABILITY_THRESHOLD_DAYS, SEC_PER_DAY } from "../../constants";

// https://runelabs.atlassian.net/browse/SW-2470
/* eslint-disable @typescript-eslint/no-explicit-any */

export type PatientMetricData = {
  id: string;
  organizationId: string;
  metricCategoryTimeRangess: {
    metricCategory: string;
    startTime: number;
    endTime: number;
  }[];
  patient: {
    id: string;
    createdAt: string;
    identifiableInfo?: {
      realName: string;
      email: string;
    };
    appMode?: {
      id: string;
      displayName: string;
    };
    patientAccessList?: {
      patientAccess: PatientAccess[];
    };
  };
  [key: string]: any;
};

export type PatientAccess = {
  startTime: number;
  endTime: number;
  org: {
    id: string;
  };
};

type Patient = {
  id: string;
  createdAt: string;
  realName: string;
  email: string;
  [key: string]: any;
  patientAccessList?: PatientAccess[];
  // include other properties as needed
};

/**
 * Converts an id that is one of the variants  like org-e6f731568765467db4da8248f89cecba,org
 * or org-e6f731568765467db4da8248f89cecba to just the hex
 * e6f731568765467db4da8248f89cecba.
 * @param id
 * @returns
 */
export function canonicalize(id: string): string | null {
  const hexRe = /[0-9a-fA-F]{32}/;
  const matches = id.match(hexRe);
  return matches ? matches[0] : null;
}

/**
 * Filter patient access list to the first access that matches
 * a provided org.
 */
export function filterPatientAccess(
  patientAccessList: PatientAccess[] | undefined,
  orgId: string
): PatientAccess | undefined {
  if (patientAccessList) {
    for (const access of patientAccessList) {
      if (canonicalize(access.org.id) === canonicalize(orgId)) {
        return access;
      }
    }
  }
}

const buildQueryProjectPatient = (
  fetchType: string,
  params: any
): BuildQueryResult => {
  let gqlQuery = `mutation($projectId: ID!) { \n`;

  switch (fetchType) {
    case QueryTypes.CREATE:
      if (
        params.data.status === "UNKNOWN_PATIENT" ||
        params.data.status === "PATIENT_NOT_FOUND"
      ) {
        // In this section, we either retrieve the patient id based on the device ID
        // or add it to the current org if it is not attached yet
        return {
          query: gql`
            mutation createPatientAccessByDevice(
              $input: CreatePatientAccessInput!
            ) {
              createPatientAccess(input: $input) {
                patientAccess {
                  id
                  org {
                    id
                  }
                  patient {
                    id
                    codeName
                    deviceList {
                      devices {
                        deviceShortId
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            input: {
              deviceId: params.data.device_id
            }
          },
          parseResponse: (response) => {
            return {
              data: response.data.createPatientAccess.patientAccess
            };
          }
        };
      } else if (
        params.data.status === "PATIENT_FOUND" ||
        params.data.status === "PATIENT_NOT_ADDED"
      ) {
        return {
          query: gql`
            mutation ($input: CreateProjectPatientInput!) {
              addPatientToProject(input: $input) {
                projectPatient {
                  patient {
                    codeName
                    id
                  }
                  codeName
                  createdAt
                  updatedAt
                  createdBy
                  updatedBy
                }
              }
            }
          `,
          variables: {
            input: {
              codeName: params.data.patient_codename,
              patientId: params.data.patient_id,
              projectId: params.data.project_id
            }
          },
          parseResponse: (response) => {
            const data = response.data.addPatientToProject.projectPatient;
            data.id =
              response.data.addPatientToProject.projectPatient.patient.id;
            return {
              data: data
            };
          }
        };
      }
      return {
        query: gql``,
        variables: {
          orgId: params.org_id,
          deviceId: params.device_id
        },
        parseResponse: (response) => {
          return {
            data: response.data,
            total: response.data.length
          };
        }
      };
    case QueryTypes.UPDATE:
      return {
        query: gql`
          mutation ($input: UpdateProjectPatientInput!) {
            updateProjectPatient(input: $input) {
              projectPatient {
                patient {
                  codeName
                  id
                }
                codeName
              }
            }
          }
        `,
        variables: {
          input: {
            codeName: params.data.codeName,
            patientId: params.data.patient.id,
            projectId: params.data.projectId
          }
        },
        parseResponse: (response) => {
          const projectPatient =
            response.data?.updateProjectPatient?.projectPatient;
          const id = `${params.data.projectId}${projectPatient.patient.id}`;
          response.data.id = id;
          return {
            data: response.data,
            total: response.data.length
          };
        }
      };

    case QueryTypes.GET_MANY_REFERENCE:
      return {
        query: gql`
          query getProject(
            $projectId: ID,
            $metricCategories: [MetricCategoryEnum]
          ) {
            project(id: $projectId) {
              id
              organizationId
              startedAt
              projectPatientList {
                projectPatients {
                  patient {
                    id
                    striveUserId
                    createdAt
                    ${
                      params.filter.canSeePII
                        ? "identifiableInfo { realName email }"
                        : ""
                    }
                    appMode {
                      id
                      displayName
                    }
                      patientAccessList {
                        patientAccess {
                            startTime
                            endTime
                            org {
                                id
                            }
                        }
                      }
                  }
                  codeName
                  metricCategoryTimeRanges(metricCategories: $metricCategories) {
                    metricCategory
                    startTime
                    endTime
                  }
                }
                pageInfo {
                  codeNameEndCursor
                  metricsEndCursor
                }
              }
            }
          }
        `,
        variables: {
          projectId: params.id,
          timeInterval: params.filter.dataAvailability,
          metricCategories: params.filter.metricCategories
        },
        parseResponse: (response) => {
          const dataAvailabilityThresholdDaysInSeconds =
            DATA_AVAILABILITY_THRESHOLD_DAYS * SEC_PER_DAY;
          const projectPatients =
            response.data.project.projectPatientList.projectPatients;
          const orgId = response.data.project.organizationId;
          const metricsData = projectPatients.map(
            (projectPatient: PatientMetricData) => {
              const access = filterPatientAccess(
                projectPatient.patient.patientAccessList?.patientAccess,
                orgId
              );
              const res: any = {
                ...projectPatient,
                id: projectPatient.patient.id,
                createdAt: projectPatient.patient.createdAt,
                realName: projectPatient.patient.identifiableInfo?.realName,
                email: projectPatient.patient.identifiableInfo?.email,
                appMode: projectPatient.patient.appMode?.displayName,
                projectStartTime: access?.startTime,
                projectEndTime: access?.endTime
              };
              for (const metricCategoryTimeRange of projectPatient.metricCategoryTimeRanges) {
                res[metricCategoryTimeRange.metricCategory] =
                  metricCategoryTimeRange.endTime;
              }

              return res;
            }
          );

          const totalPatients = metricsData.length;
          const categoryCounts: { [key: string]: number } = {};
          const noDataForTenDaysPatients = new Set();

          for (const metricCategory of params.filter.metricCategories) {
            categoryCounts[metricCategory] = 0;
          }

          metricsData.forEach((patient: PatientMetricData) => {
            let noDataForAnyCategory = false;

            params.filter.metricCategories.forEach((metricCategory: string) => {
              const endTime = patient[metricCategory];
              if (
                isDataOutdated(endTime, dataAvailabilityThresholdDaysInSeconds)
              ) {
                categoryCounts[metricCategory]++;
                noDataForTenDaysPatients.add(patient.id);
                noDataForAnyCategory = true;
              }
            });

            if (noDataForAnyCategory) {
              noDataForTenDaysPatients.add(patient.id);
            }
          });

          const filteredPatients = metricsData.filter((patient: Patient) => {
            if (params.filter.qcMetricCategory === "ANY") {
              return noDataForTenDaysPatients.has(patient.id);
            } else {
              const endTime = patient[params.filter.qcMetricCategory as string];
              return isDataOutdated(
                endTime,
                dataAvailabilityThresholdDaysInSeconds
              );
            }
          });

          return {
            data: filteredPatients,
            total: totalPatients,
            categoryCounts: categoryCounts,
            noDataForAnyCategoryCount: noDataForTenDaysPatients.size
          };
        }
      };

    case QueryTypes.DELETE_MANY:
      params.ids.forEach((id: string, index: number) => {
        gqlQuery +=
          "aliasDelete" +
          index +
          ': removePatientFromProject(projectId: $projectId, patientId: "' +
          id +
          '") { status } \n';
      });
      gqlQuery += "}\n";

      return {
        query: gql(gqlQuery),
        variables: {
          projectId: params.meta.projectId
        },
        parseResponse: () => {
          return { data: [] };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryProjectPatient;
